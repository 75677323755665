import React, {useState} from 'react';
import '../home_left/style.css';
import {
    Container,
    Row,
    Col,
    Button,
    Image
} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
import {CiroModal} from "../modal"
import {default as about_me_text} from "../../const/about_me";
import {default as contact_me_text} from "../../const/contact_me";
import {techQuoteOfTheWeek} from 'tech-quote-of-the-week';

function Home_Left() {
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState(about_me_text.text);
    const [body, setBody] = useState(about_me_text.body);
    const [footer, setFooter] = useState(about_me_text.footer);

    const setModal = (title, body, footer) => {
        setShow(true)
        setTitle(title);
        setBody(body);
        setFooter(footer);
    }
    const handleClose = () => setShow(false);

    return (
        <Container className="home-left">
            <Row className="home-left-main">
                <Col xl={12} className="home-left-main-col">
                    <h1 className="first-line">
                        Hi!
                    </h1>
                    <h2 className="second_line">
                        Have you met <strong>ciro</strong>?
                    </h2>
                    <h4 className="typical"> Software Engineer</h4>
                    <br/>
                    <h3>
                        <ReactTypingEffect
                            text="Programming and technology enthusiast, with a rolling release life."
                            className="typical"
                            speed="10"
                            eraseDelay="500000"
                            typingDelay="2000"
                        />
                    </h3>
                    <h3>
                        <ReactTypingEffect
                            text={[
                                "Skills in many programming languages and computer science.",
                                "Attitude in Co-Working and team management.",
                                "I have no particular talents, I'm just passionately curious. And you?",
                                `${techQuoteOfTheWeek()().text}.`
                            ]}
                            className="typical"
                            speed="10"
                            eraseDelay="5000"
                            eraseSpeed="10"
                            typingDelay="2000"
                        />
                    </h3>
                    <br/>
                    <Button onClick={() => setModal(about_me_text.title, about_me_text.body, about_me_text.footer)}
                            variant="outline-light" size="lg" className="home-left-aboutmme-btn">
                        About Me
                    </Button>
                    &nbsp;
                    <Button
                        onClick={() => setModal(contact_me_text.title, contact_me_text.body, contact_me_text.footer)}
                        variant="outline-light" size="lg" className="home-left-aboutmme-btn">
                        Contact Me
                    </Button>
                    <CiroModal show={show} handleClose={handleClose} title={title} body={body} footer={footer}/>
                </Col>
            </Row>
        </Container>
    );
}

export default Home_Left;
