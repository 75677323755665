import React, {useState} from "react";
import {Modal} from "react-bootstrap";

export function CiroModal({show, handleClose, title, body, footer}) {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                {/*<Modal.Footer>{footer}</Modal.Footer>*/}
            </Modal>
        </>
    );
}
