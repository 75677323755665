import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "../pages/home";

export default function Nav() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="*" component={Home}/>
            </Switch>
        </Router>
    );
}
