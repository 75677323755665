import React from "react";
import ContactMe from "../assets/img/contact_me.gif";
import {Image} from "react-bootstrap";

const contact_me = {
    title: "Get In Touch!",
    body:
        <div>
            <Image src={ContactMe} className="home-right-main-img" alt="Contact me gif"/>
            <p>
                Whether you want to get in touch, talk about a project collaboration, or just say hi, I'd love to hear
                from you.
                <br/>
                <br/>
                Simply send me an <a href="mailto:ciro1693@gmail.com">email</a>. <span role="img" aria-label="sheep">😄</span>
            </p>
        </div>,
    footer: ""
}
export default contact_me
