import React, {useState} from 'react';
import '../home_right/style.css';
import Ciro from '../../assets/img/ciro.png';
import {
    Container,
    Row,
    Col,
    Image,
} from 'react-bootstrap';
import SocialIcons from '../social_icons';
import {toast, Flip} from 'react-toastify';
import Quote from 'inspirational-quotes';

function Home_Right() {
    const [fun, setFun] = useState(0);

    return (
        <Container className="home-right">
            <Row className="home-right-main">
                <Col xl={12}>
                    <br/>
                    <Image src={Ciro} className="home-right-main-img" alt="Image of Ciro Santillo" onClick={() => {
                        if (fun > 0) {
                            const quote = Quote.getQuote()
                            toast.success(
                                <div>
                                    You are curious, Good!
                                    <br/>
                                    This is a secret for you:
                                    <br/>
                                    <br/>
                                    <span style={{fontStyle: "italic"}}>
                                        «{quote.text}»
                                    </span>
                                    <br/>
                                    - {quote.author}
                                </div>,
                                {transition: Flip, theme: "dark"}
                            )
                        } else {
                            setFun(fun + 1)
                        }
                    }}/>
                </Col>
                <SocialIcons/>
            </Row>
        </Container>
    );
}

export default Home_Right;
