import React from 'react';
import '../home/style.css';
import HomeLeft from '../../components/home_left';
import HomeRight from '../../components/home_right';
import Footer from '../../components/footer';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import Animate from 'react-smooth';
import Particles from 'react-particles-js';
import particles from '../../const/particle.js';
import {Helmet} from 'react-helmet';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Home() {
    return (
        <div>
            <ToastContainer/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Ciro Santillo</title>
                <link rel="canonical" href="https://riccio.me/"/>
                <meta name="description" content="Ciro Santillo | Home - Software Engineer | Project Manager"/>
            </Helmet>
            <Animate to="1" from="0" attributeName="opacity">
                <Particles
                    params={particles}
                    className="particle"
                />
                <Container className="App-header" fluid={true}>
                    <Row className="App-main">
                        <Col xl={7} className="App-left">
                            <HomeLeft/>
                        </Col>
                        <Col xl={5} className="App-right">
                            <HomeRight/>
                        </Col>
                    </Row>
                    <Row className="App-footer">
                        <Footer/>
                    </Row>
                </Container>
            </Animate>
        </div>
    );
}

export default Home;
