import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import Ciro from "../assets/img/ciro.png";
import Balto from "../assets/img/balto.png";
import CiroStudia from "../assets/img/ciro-studia.png";
import CiroTrekking from "../assets/img/ciro-trekking.png";
import CiroFinto from "../assets/img/ciro-finto.png";
import CiroRock from "../assets/img/ciro-rock.png";

const about_me = {
    title: "Whoami",
    body:
        <div>
            <Container fluid={true}>
                <h3>Who is Ciro?</h3>
                <p>
                    Welcome to my personal space in this endless network that is the Internet!
                    <br/>
                    <br/>
                    I've always had some difficulty writing my bio. Which on a website that is supposed to introduce me
                    doesn't help ...but I'll try to do my best.&nbsp;
                    <span role="img" aria-label="Grinning Face with Sweat">😅</span>
                    <br/>
                    <br/>
                    I am currently {(new Date().getFullYear() - 1993)} years old and in love with the city in
                    which I was born, Rome, in which I started many of my adventures.
                    <br/>
                    <br/>
                </p>
                <Row>
                    <Col xl={6}>
                        <Image src={CiroStudia} className="home-right-main-img" alt="Image of Ciro Santillo"/>
                    </Col>
                    <Col xl={6}>
                        <p>
                            I attended the Faculty of Computer Engineering until 2020, bringing home my master's degree
                            after a thousand ups and downs!
                            <br/>
                            <br/>
                            In the meantime, I enjoyed organizing seminars, courses, projects and activities within the
                            University, on behalf of&nbsp;<a href="https://www.lugroma.org">LUG Roma Tre</a>.
                            <br/>
                            <br/>
                            For me it was a really important experience, I learned a lot and I owe a lot to the project.
                        </p>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col xl={7}>
                        <h3>Experience</h3>
                        <p>
                            I started working from the second year of university, I participated in several Hackathons
                            and I threw myself on different technologies:
                            <ul>
                                <li>
                                    <strong>C/C++</strong>: My first languages, I approached the world of computer
                                    science in high school and it was my first love.
                                </li>
                                <li>
                                    <strong>Javascript/Node.js/React.js</strong>: The world of the web of course, a
                                    dynamic, hectic world full of NPM libraries in constant evolution.
                                </li>
                                <li>
                                    <strong>Android/Kotlin</strong>: The mobile, fun and rewarding world.
                                </li>
                                <li>
                                    <strong>Cloud</strong>: After visiting many worlds I decided to take a ride in the
                                    clouds, I designed cloud first architectures and converted multiple legacy
                                    applications into highly available scalable cloud services&nbsp;
                                    <span role="img" aria-label="Flexed Biceps">💪</span>
                                </li>
                            </ul>
                        </p>

                    </Col>
                    <Col xl={5}>
                        <Image src={CiroFinto} className="home-right-main-img" alt="Image of Ciro Santillo"/>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col xl={6}>
                        <h3>Hobby</h3>
                        <p>
                            <em>
                                "The charm of silent places, of the great silver moons and the splendor of the
                                stars... where the traveler can see the beauty of the rising and setting of
                                sun, in wild places ignored by man! This alone is capable of changing the
                                spend eternal time."
                            </em>
                            <br/>
                            <br/>
                            I am passionate about outdoor activities and more generally I love the mountains, in all
                            seasons:
                            <ul>
                                <li>
                                    I love breaking my legs with Trekking.
                                </li>
                                <li>
                                    I love to break my legs with Ski and Snowboard.
                                </li>
                                <li>
                                    I love to break my legs if you didn't understand.
                                </li>
                            </ul>
                            <br/>
                            <br/>
                            <Image src={CiroRock} className="home-right-main-img" alt="Image of Ciro Santillo"/>
                        </p>
                    </Col>
                    <Col xl={6}>
                        <Image src={CiroTrekking} className="home-right-main-img" alt="Image of Balto"/>
                        <br/>
                        <br/>
                        <p>
                            When I'm not in the mountains I love cycling and visiting Rome accompanied by my two wheels.
                            <br/>
                            <br/>
                            In the evening I go wild with some healthy Punk/Rock with my group of crazy, I love jumping
                            on stage!
                            <br/>
                            <br/>
                            Obviously there is much more, but I will not go further.
                            <br/>
                            <br/>
                            Rock On! <span role="img" aria-label="Sign of the Horns">🤘</span>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>,
    footer: ""
}
export default about_me
