import React from 'react';
import '../footer/style.css';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';

function Footer() {
    return (
        <Container className="footer" fluid={true}>
            <Row>
                <Col xl={12}>
                    By browsing, you accept the conditions of the Cookie Policy.&nbsp;
                    <a href="https://www.cookiesandyou.com/" target="_blank" rel="noopener noreferrer">
                        Learn More!
                    </a>
                    <br/>
                    Copyright © {(new Date().getFullYear())} <strong>Ciro Santillo</strong>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
